import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const MinusIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M4 8C4 7.63181 4.29848 7.33333 4.66667 7.33333H11.3333C11.7015 7.33333 12 7.63181 12 8V8C12 8.36819 11.7015 8.66667 11.3333 8.66667H4.66667C4.29848 8.66667 4 8.36819 4 8V8Z"
      fill={color}
    />
    <style jsx>{`
      svg {
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
