import React from 'react';

export const VisuallyHidden: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <span>
    {children}
    <style jsx>{`
      span {
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
    `}</style>
  </span>
);
