import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
  variant?: 'default' | 'thick';
}

export const SearchIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = 'currentColor',
  size = 24,
  variant = 'default',
  ...props
}) => {
  const variants = {
    default:
      'M10 5.5C7.51472 5.5 5.5 7.51472 5.5 10C5.5 12.4853 7.51472 14.5 10 14.5C12.4853 14.5 14.5 12.4853 14.5 10C14.5 7.51472 12.4853 5.5 10 5.5ZM4.5 10C4.5 6.96243 6.96243 4.5 10 4.5C13.0376 4.5 15.5 6.96243 15.5 10C15.5 11.3387 15.0217 12.5658 14.2266 13.5195L18.6036 17.8964C18.7988 18.0917 18.7988 18.4083 18.6036 18.6036C18.4083 18.7988 18.0917 18.7988 17.8964 18.6036L13.5195 14.2266C12.5658 15.0217 11.3387 15.5 10 15.5C6.96243 15.5 4.5 13.0376 4.5 10Z',
    thick:
      'M14.8907 13.4765C15.5892 12.4957 16 11.2958 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C11.2958 16 12.4957 15.5892 13.4765 14.8907L17.5563 18.9706C17.9469 19.3611 18.58 19.3611 18.9706 18.9706C19.3611 18.58 19.3611 17.9469 18.9706 17.5563L14.8907 13.4765ZM10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z',
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={variants[variant]}
        fill={color}
      />
      <style jsx>{`
        svg {
          height: ${size}px;
          width: ${size}px;
          display: block;
        }
      `}</style>
    </svg>
  );
};
