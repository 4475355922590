import classnames from 'classnames';
import React, { forwardRef, useRef, useState } from 'react';

import { useTheme } from '../../contexts/Theme';
import { pxToRem } from '../../lib/pxToRem';
import { Button } from '../Button';
import { CaretDownIcon } from '../Icons/CaretDownIcon';
import { CheckIcon } from '../Icons/CheckIcon';
import { MinusIcon } from '../Icons/MinusIcon';
import { PlusIcon } from '../Icons/PlusIcon';
import { SearchIcon } from '../Icons/SearchIcon';
import { LoadingSpinner } from '../LoadingSpinner';
import { Text } from '../Text';
import { VisuallyHidden } from '../VisuallyHidden';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  infoText?: string;
  loading?: boolean;
  numberDecreaseLabel?: string;
  numberIncreaseLabel?: string;
  suffix?: React.ReactNode;
  valid?: boolean;
  variant?: 'default' | 'small';
}

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      error = false,
      infoText,
      loading = false,
      numberDecreaseLabel = 'Decrease',
      numberIncreaseLabel = 'Increase',
      onChange,
      suffix,
      type = 'text',
      valid,
      variant = 'default',
      ...props
    },
    forwardedRef: React.MutableRefObject<HTMLInputElement>
  ) => {
    const { colors, fontFamilies, fontSizes, lineHeights } = useTheme();
    const innerRef = useRef<HTMLInputElement>(null);
    const ref = forwardedRef || innerRef;
    const [value, setValue] = useState('');

    const classNames = classnames(className, {
      error,
      hasSuffix: !!suffix,
      valid,
    });

    // For iOS
    const isDateOrTime = type === 'date' || type === 'time';
    let fakePlaceholder = null;
    switch (type) {
      case 'date':
        fakePlaceholder = 'YYYY-MM-DD';
        break;
      case 'time':
        fakePlaceholder = 'HH:mm';
    }

    const iconSize = variant === 'small' ? 16 : 24;
    const iconVariant = variant === 'small' ? 'thick' : 'default';

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setValue(ev.target.value);
      onChange?.(ev);
    };

    /**
     * Sets up and dispatches an 'input' event for React.
     * @param value The new value.
     */
    const changeValue = (value: string) => {
      var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      ).set;
      nativeInputValueSetter.call(ref.current, value);
      const ev = new Event('input', { bubbles: true });
      ref.current.dispatchEvent(ev);
      setValue(value);
    };

    const handleMinusClick = () => {
      const min = Boolean(ref.current.min) ? Number(ref.current.min) : null;
      const step = Boolean(ref.current.step) ? Number(ref.current.step) : 1;
      if (min !== null && ref.current.valueAsNumber <= min) return;
      if (isNaN(ref.current.valueAsNumber)) {
        changeValue(min !== null ? min.toString() : '1');
        return;
      }
      changeValue('' + (ref.current.valueAsNumber - step));
    };
    const handlePlusClick = () => {
      const max = Boolean(ref.current.max) ? Number(ref.current.max) : null;
      const min = Boolean(ref.current.min) ? Number(ref.current.min) : null;
      const step = Boolean(ref.current.step) ? Number(ref.current.step) : 1;
      if (max !== null && ref.current.valueAsNumber >= max) return;
      if (isNaN(ref.current.valueAsNumber)) {
        changeValue(min !== null ? min.toString() : '1');
        return;
      }
      changeValue('' + (ref.current.valueAsNumber + step));
    };

    return (
      <div className="inputWrapper">
        <div
          className={classnames('group', {
            disabled: props.disabled,
            hasError: error,
            hasSuffix: suffix,
          })}
        >
          {type === 'number' && (
            <Button
              className="minus"
              disabled={Number(value) <= Number(props?.min)}
              variant="secondary"
              onClick={handleMinusClick}
              // Number fields can be controlled with keyboard, so we can skip the buttons.
              tabIndex={-1}
            >
              <VisuallyHidden>{numberDecreaseLabel}</VisuallyHidden>
              <MinusIcon size={16} />
            </Button>
          )}
          <div
            className={classnames('input', variant, {
              number: type === 'number',
              search: type === 'search',
            })}
          >
            {type === 'search' && (
              <span className="icon search">
                {loading ? (
                  <LoadingSpinner size={iconSize} />
                ) : (
                  <SearchIcon
                    color="var(--input-placeholder-color)"
                    size={iconSize}
                    variant={iconVariant}
                  />
                )}
              </span>
            )}
            <input
              ref={ref}
              className={classNames}
              type={type}
              onChange={handleChange}
              {...props}
            />
            {isDateOrTime && (
              <>
                {!ref?.current?.value && (
                  <Text El="span" color="muted" className="ios placeholder">
                    {fakePlaceholder}
                  </Text>
                )}
                {!valid && isDateOrTime && (
                  <span className="ios icon">
                    <CaretDownIcon size={16} />
                  </span>
                )}
              </>
            )}
            {valid && (
              <span className="icon">
                <CheckIcon color={colors.accent} size={16} />
              </span>
            )}
            {infoText && (
              <Text El="span" className="infoText" color="muted">
                {infoText}
              </Text>
            )}
          </div>
          {suffix && <div className="suffix">{suffix}</div>}
          {type === 'number' && (
            <Button
              className="plus"
              variant="secondary"
              disabled={Number(value) >= Number(props?.max)}
              onClick={handlePlusClick}
              // Number fields can be controlled with keyboard, so we can skip the buttons.
              tabIndex={-1}
            >
              <VisuallyHidden>{numberIncreaseLabel}</VisuallyHidden>
              <PlusIcon size={16} />
            </Button>
          )}
        </div>
        <style jsx>{`
          .inputWrapper {
            width: 100%;
          }
          .group {
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            width: 100%;
          }
          .group.hasSuffix {
            border: 1px solid var(--input-border);
            box-shadow: 0 0 0 0 var(--focus-shadow);
            transition: border 200ms ease, box-shadow 200ms ease;
          }
          .group.hasSuffix:not(.disabled):hover,
          .group.hasSuffix:not(.disabled):focus-within {
            border: 1px solid var(--focus-border);
            box-shadow: 0 0 0 2px var(--focus-shadow);
          }
          .group.hasSuffix.hasError {
            border: 1px solid var(--input-error-border);
            box-shadow: 0 0 0 0 var(--input-error-shadow);
          }
          .group.hasSuffix.hasError:not(.disabled):hover,
          .group.hasSuffix.hasError:not(.disabled):focus-within {
            box-shadow: 0 0 0 2px var(--input-error-shadow);
          }
          .group.disabled {
            opacity: 0.6;
          }
          .input {
            position: relative;
            width: 100%;
          }
          .input.search input {
            padding-left: 32px;
          }
          input {
            background: var(--input-bg);
            border: 1px solid var(--input-border);
            border-radius: 8px;
            caret-color: var(--input-color);
            color: var(--input-color);
            display: block;
            font-family: ${fontFamilies.regular};
            font-size: ${fontSizes.base};
            font-weight: normal;
            height: 32px;
            line-height: ${lineHeights.base};
            padding: 5px 8px 7px;
            position: relative;
            transition: border 200ms ease, box-shadow 200ms ease;
            width: 100%;
            z-index: 2;
          }
          input.hasSuffix {
            border: none;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
          .suffix {
            align-items: center;
            background-color: var(--input-suffix-bg);
            border: 1px var(--input-border);
            border-radius: 0 8px 8px 0;
            color: var(--input-suffix-color);
            display: flex;
            height: 32px;
            justify-content: center;
            margin-left: -1px;
            margin-right: auto;
            padding: 6px 8px;
            white-space: nowrap;
          }
          input:not(.hasSuffix):not([disabled]):focus,
          input:not(.hasSuffix):not([disabled]):hover {
            border-color: var(--focus-border);
            box-shadow: 0 0 0 2px var(--focus-shadow);
          }
          input:not([disabled]):focus {
            outline: none;
          }
          input::placeholder {
            color: var(--input-placeholder-color);
          }
          input:-webkit-autofill {
            /* background doesn't work */
            /* see https://developer.mozilla.org/en-US/docs/Web/CSS/:-webkit-autofill */
            box-shadow: inset -1px 0 0 32px var(--input-bg),
              inset 0 -1px 0 32px var(--input-bg);
            color: var(--input-color);
            -webkit-text-fill-color: var(--input-color);
          }
          input[disabled] {
            background-color: var(--input-disabled-bg);
            color: var(--input-disabled-color);
          }
          input[disabled]::placeholder {
            color: var(--input-disabled-placeholder-color);
          }
          :global(form.submitted) input:not(:focus):invalid:not([disabled]),
          input.error:not([disabled]),
          input.error:not([disabled]):focus,
          input.error:not([disabled]):hover {
            border-color: var(--input-error-border);
            color: var(--input-error-color);
          }
          input.error:not(.hasSuffix):not([disabled]):focus,
          input.error:not(.hasSuffix):not([disabled]):hover {
            box-shadow: 0 0 0 2px var(--input-error-shadow);
          }
          .icon {
            display: inline-flex;
            pointer-events: none;
            position: absolute;
            right: 8px;
            top: 8px;
            z-index: 3;
          }
          .icon.search {
            left: 4px;
            right: auto;
            top: 4px;
          }
          .inputWrapper :global(.ios) {
            display: none;
          }
          input[type='search']::-webkit-search-cancel-button {
            display: none;
          }
          input[type='search']::-webkit-search-decoration {
            display: none;
          }
          .small input {
            height: 24px;
            padding: 3px 8px 5px;
          }
          .small.search input {
            padding-left: 24px;
          }
          .small + .suffix {
            height: 24px;
            padding: 3px 8px 5px;
          }
          .input :global(.infoText) {
            position: absolute;
            right: 8px;
            top: 7px;
            z-index: 5;
          }
          .small :global(.infoText) {
            top: 3px;
          }
          input[type='number']::-webkit-inner-spin-button,
          input[type='number']::-webkit-outer-spin-button {
            appearance: none;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
          .group :global(.minus) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            margin-right: -1px;
            padding-inline: 8px;
          }
          .number input {
            border-radius: 0;
          }
          .group :global(.plus) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            margin-left: -1px;
            padding-inline: 8px;
          }
          .group :global(.minus:not([disabled]):focus-visible),
          .group :global(.minus:not([disabled]):hover),
          .group :global(.plus:not([disabled]):focus-visible),
          .group :global(.plus:not([disabled]):hover) {
            z-index: 5;
          }
          .group.hasError :global(.minus:not([disabled]):focus-visible),
          .group.hasError :global(.minus:not([disabled]):hover),
          .group.hasError :global(.plus:not([disabled]):focus-visible),
          .group.hasError :global(.plus:not([disabled]):hover) {
            border-color: var(--input-error-border);
            box-shadow: 0 0 0 2px var(--input-error-shadow);
          }
          @supports (-webkit-touch-callout: none) {
            /* iOS specific style adjustments */
            .inputWrapper :global(.ios.placeholder) {
              /* Fake placeholder for date and time input */
              color: var(--input-placeholder-color);
              display: block;
              font-size: ${pxToRem(16)};
              left: 9px;
              pointer-events: none;
              position: absolute;
              top: 8px;
              z-index: 2;
            }
            .inputWrapper .ios.icon {
              /* Selector icon for date and time input */
              display: inline-flex;
              pointer-events: none;
              position: absolute;
              right: 8px;
              top: 8px;
              z-index: 3;
            }
            input {
              /* Font size of 16px to disable zoom on focus */
              font-size: ${pxToRem(16)};
              padding: 5px 8px;
            }
            input:not(.hasSuffix):not([disabled]):focus,
            input:not(.hasSuffix):not([disabled]):hover {
              // iOS ignores box-shadow, unless -webkit-appearance: none; is set
              // https://discourse.webflow.com/t/box-shadow-not-working-properly-in-ios/123553
              -webkit-appearance: none;
              box-shadow: 0 0 0 2px var(--focus-shadow);
            }
            input::placeholder {
              padding: 3px 0;
            }
            input[type='date'],
            input[type='time'] {
              -webkit-appearance: none;
              padding: 7px 8px;
            }
            input[type='search'] {
              -webkit-appearance: none;
              border-radius: 8px;
            }
            .small input {
              padding: 3px 8px;
            }
          }
          :global(.theme-dark)
            input[type='date']::-webkit-calendar-picker-indicator,
          :global(.theme-dark)
            input[type='time']::-webkit-calendar-picker-indicator {
            filter: invert(1);
          }
        `}</style>
      </div>
    );
  }
);
